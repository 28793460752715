:root {
  --primary-color: #4a90e2;
  --secondary-color: #7e57c2;
  --background-color: #f4f7f9;
  --text-color: #2c3e50;
  --gradient-primary: linear-gradient(135deg, #4a90e2 0%, #7e57c2 100%);
  --shadow-soft: 0 10px 25px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-content {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  box-shadow: var(--shadow-soft);
  position: relative;
  overflow: hidden;
  transform: scale(0.9);
  transition: all 0.3s ease;
  border: 1px solid rgba(74, 144, 226, 0.1);
}

.popup-content:hover {
  box-shadow: var(--shadow-hover);
  transform: scale(0.95);
}

.popup-header {
  background: var(--gradient-primary);
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.popup-close:hover {
  background: rgba(255, 255, 255, 0.4);
}

.popup-inner {
  padding: 30px;
  background: var(--background-color);
}

.popup-inner h2 {
  color: var(--text-color);
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.form-control {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 16px;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.btn-primary {
  width: auto;
  padding: 12px 15px;
  background: var(--gradient-primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-primary:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(126, 87, 194, 0.3);
}

.popup-ai-icon {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 100px;
  height: 100px;
  background: rgba(74, 144, 226, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}

.popup-ai-icon svg {
  width: 50px;
  height: 50px;
  color: var(--primary-color);
}

@media (max-width: 600px) {
  .popup-content {
    width: 95%;
    margin: 0 10px;
  }
  
  .popup-inner {
    padding: 20px;
  }
}
